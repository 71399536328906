export default function (Component) {
      Component.options.frontmatter = {
  "published": "2017-03-03T00:00:00.000Z",
  "updated": "2019-05-01T00:00:00.000Z",
  "description": "A memory log of my first day using Vue.js.",
  "tags": [
    "vue",
    "experience",
    "javascript"
  ],
  "collection": "Vue",
  "title": "First Day in Vue.js"
}
    }